.purposes_container h3 {
    font-family: 'Playfair Display', serif;
    /*font-size: 15px;*/
    color: black;
}

h2 {
    text-align: center;
    margin-bottom: 25px;
    margin-top: 20px;
}

.purpose_h4 {
    font-family: 'Playfair Display', serif;
    font-weight: normal;
    height: 35px;
    color: black;
    font-size: 16px;
    height: 40px;
    text-align: center;
}

.purposes_top,
.purposes_bottom {
    display: flex;
    flex-direction: row;
    margin-bottom: 35px;
}

.purpose {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.purpose_bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 25px;
}

.purpose_general, .purpose_planetary {
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.purpose_general p {
    font-size: 16px;
    width: 300px;
}

#circle_soul {
    margin-top: 40px;
    background: transparent;
    color: black;
}

@media screen and (max-width: 800px) {
    .purpose_general p {
        font-size: 12px;
        width: 300px;
    }

    #circle_soul {
        margin-top: 0px;
    }
}


.circle_purpose {
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 38px;
    height: 38px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    border: 1px solid black;
    font-weight: normal;
    font-family: 'Playfair Display', serif;
    font-size: 23px;
    color: black;
    background: transparent;
}

.circle_sum {
    /*background: #F5E9E4;*/
    background: transparent;
    color: black;
}

.purpose_h3 {
    font-size: 16px;
    text-align: center;
}

.word {
    font-family: 'Playfair Display', serif;
    color: black;
}

p {
    font-size: 12px;
    width: 230px;
    text-align: center;
    min-height: 45px;
    font-family: 'Playfair Display', serif;
    color: black;
}

.p-with-margin {
    margin-bottom: 15px;
    font-size: 15px;
}

@media screen and (max-width: 800px) {
    .p-with-margin {
        font-size: 12px;
    }
}


.span {
    width: 10px;
    height: calc(50% - 10px);
    display: inline-block;
}

.rect {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 7px;
}

.left-brace-tl {
    border-right: 2px solid grey;
    border-bottom-right-radius: 10px;
    margin-right: -2px;
    margin-top: 10px;
}

.left-brace-tr {
    margin-bottom: 10px;
    border-left: 2px solid grey;
    border-top-left-radius: 10px;
}

.left-brace-bl {
    margin-top: -4px;
    border-right: 2px solid grey;
    border-top-right-radius: 10px;
    margin-right: -2px;
}

.left-brace-br {
    margin-bottom: -8px;
    border-left: 2px solid grey;
    border-bottom-left-radius: 10px;
}

.right-border {
    height: 100px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    width: 100px;
    height: 100px;
}

.circle_solo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
}

.circle_solo .circle {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-family: 'Playfair Display', serif;
    font-size: 23px;
    border: 1px solid black;
    font-weight: normal;
    background: none;
    color: black;
}

.content_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    font-weight: bold;
    color: black;
    font-family: 'Playfair Display', serif;
}

.own_power_code {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.own_power_code-items {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.own_power_code-item {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 33%;
    font-size: 13px;
}

.map-of-year {
    margin-top: 25px;
}

.map-of-year_items {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    width: 100%;
}


@media screen and (max-width: 800px) {
    .circle_purpose {
        font-size: 18px;
        border: 1px solid black;
        height: 30px;
        width: 30px;
        color: black;
        background: transparent;
    }

    .circle_sum {
        color: black;
    }

    #circle_soul {
        background: transparent;
    }

    p {
        width: 180px;
        margin-bottom: 20px;
    }

    .purpose {
        justify-content: space-between;
    }

    .content_row {
        font-size: 13px;
    }

    .word {
        padding-left: 10px;
        justify-content: space-around;
        font-family: 'Playfair Display', serif;
    }

    .purpose_bottom {
        justify-content: space-around;

    }

    .own_power_code-items {
        justify-content: center;
        gap: 3px;

    }

    .own_power_code-item {
        font-size: 10px;
        justify-content: center;
        width: 100px;
    }

    .map-of-year_items .circle_solo {
        width: 75px;
        /*border: 1px solid red;*/
    }

    .circle_solo {
        font-size: 15px;
        height: 50px;
    }

    .circle_solo .circle {
        font-size: 18px;
        border: 1px solid black;
        height: 30px;
        width: 30px;
        color: black;
    }

    .purpose_h4 {
        font-size: 12px;
        letter-spacing: 0.5px;
        width: 70px;
        height: 35px;
        text-align: center;
        font-weight: normal;
    }


}
