.birth-data {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 112px;
    width: 270px;
    font-size: 13px;
    gap: 4px;
    margin-top: 7vh;
}
.birth-data .btn {
    height: 40px;
    font-size: 18px;
}
.birth-information {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 3px;
    width: 260px;
}

.birth-information_item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    font-family: 'Playfair Display', serif;
    font-size: 14px;
}
.birth-information_item b {
    font-family: 'Playfair Display', serif;
}

@media screen and (max-width: 1525px) {
    .birth-data {
        align-items: center;
    }
}


@media screen and (max-width: 800px) {
    .birth-information {
        flex-direction: row;
        gap: 3px;
        width: 230px;
    }

    .birth-information_item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;
        font-family: 'Playfair Display', serif;
        font-size: 14px;
    }

}


.btn {
    width: 100%;
    height: 35px;
    font-size: 15px;
    letter-spacing: 0.2px;
    color: black;
    border: none;
    border-radius: 50px;
    margin-top: 5px;
    background: radial-gradient(circle,rgba(246,239,219,1)  0%, rgba(204,172,126,1) 100%);
    /*background: -webkit-linear-gradient(45deg, rgb(255, 231, 237) 0%, #f9dce1 25%, #bdbdf8 50%, #caeeec 75%, #caeeec 100%);*/
    /*background: linear-gradient(45deg, rgb(255, 231, 237) 0%, #f9dce1 25%, #bdbdf8 50%, #caeeec 75%, #caeeec 100%);*/
    /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);*/
    box-shadow: 0px 3px 10px rgba(0, 0, 0, .25);
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
}

.btn:hover {
    cursor: pointer;
    background: radial-gradient(circle, rgba(204,172,126,1) 0%, rgba(180,147,120,1) 100%);
}