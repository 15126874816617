
.matrix-selection-page_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 55px;
  align-items: center;
  //height: 90vh;
  height: 0vh;
  margin: auto;

  .social-and-photo {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10%;

    .img-hero {
      position: relative;
      margin-right: 0px;


      &::before {
        //border: 1px solid red;
        //width: 70vh;
        width: 90vh;
        content: "";
        background-image: url('../../../assets/hero2.png');
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: -100vh;
        right: 0vw;
        bottom: -32.5vh;
        left: -64vw;
        overflow: visible;
        @media screen and (max-width: 1650px) {
          left: -67vw;
          top: -92vh;
          bottom: -30.5vh;
        }
        @media screen and (max-width: 1610px) {
          left: -45vw;
          bottom: -30.5vh;
        }
        @media screen and (max-width: 1268px) {
          display: none;
        }
      }
    }

    .social-links_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 5px;
      width: 400px;
      margin-top: 40px;

      .social-links {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 15px;
        position: absolute;
        bottom: 30%;
        z-index: 1;
        @media screen and (max-width: 1624px) {
          bottom: 20%;
        }
      }
    }
  }

  .matrix-selection-page_wrapper_h1 {
    position: relative;
    z-index: 100;
    font-size: 100px;
    font-weight: normal;
    font-family: 'Cormorant', serif;
    //color: #B49378;
    color: #B49378;
  }

  .matrix-selection-page_wrapper_h3 {
    position: relative;
    z-index: 100;
    font-size: 25px;
    font-weight: normal;
    font-family: 'Cormorant', serif;
    ccolor: #B49378;
    margin-bottom: -50px;
  }


  .buttons_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 10px;
    width: 100%;
    z-index: 100;

    .buttons_wrapper-btn {
      color: white;
      max-width: 320px;
      height: 50px;
      background: radial-gradient(circle, rgba(246, 239, 219, 1) 0%, rgba(204, 172, 126, 1) 100%);
      box-shadow: 0px 3px 10px rgba(0, 0, 0, .25);
      font-weight: normal;
      color: black;
      font-size: 13px;

      &:hover {
        background: radial-gradient(circle, rgba(204, 172, 126, 1) 0%, rgba(180, 147, 120, 1) 100%);
      }
    }
  }

  .social-links_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 25px;
    width: 400px;
    margin-top: 40px;
  }
}

@media screen and (max-width: 1610px) {
  .img-hero {
    position: relative;
    width: 900px;
    margin-right: -20px;

    &::before {
      width: 370px;
      content: "";
      //background-image: url('../../../assets/hero2.png');
      background-image: url('../../../assets/hero1.png');
      background-size: cover;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: -52px;
      overflow: hidden;
    }
  }
  .buttons_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 10px;
    max-width: 700px;

    .buttons_wrapper-btn {
      max-width: 320px;
      height: 50px;
    }
  }
}

@media screen and (max-width: 800px) {

  .matrix-selection-page_wrapper {
    gap: 20px;

    .social-and-photo {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 59%;
      //position: relative;

      .img-hero {
        position: relative;
        width: 1310px;
        margin-right: -20px;

        &::before {
          width: 370px;
          content: "";
          background-image: url('../../../assets/hero1.png');
          background-size: cover;
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: -52px;
          overflow: hidden;
        }
      }

      .social-links_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        width: 400px;
        margin-top: 40px;

        .social-links {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 5px;
          width: 400px;
          margin-top: 40px;
          position: absolute;
          bottom: 30%;
          z-index: 1;
        }
      }
    }


    .matrix-selection-page_wrapper_h1 {
      font-size: 60px;
    }

    .matrix-selection-page_wrapper_h3 {
      font-size: 15px;
      margin-bottom: -30px;
    }

    .buttons_wrapper {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;

      .buttons_wrapper-btn {
        max-width: 250px;
        font-size: 12px;
        padding: 15px;
        height: 45px;
      }
    }
  }
}

@media screen and (max-width: 470px) {
  .matrix-selection-page_wrapper {
    gap: 35px;
    width: 100%;
    justify-content: flex-end;

    .social-and-photo {
      .img-hero {
        &::before {
          display: block;
          width: 530px;
          content: "";
          background-image: url('../../../assets/hero1.png');
          background-size: cover;
          position: absolute;
          top: -16vh;
          right: 0px;
          bottom: -50vh;
          left: -150px;
          //overflow: hidden;
        }
      }
    }

    .matrix-selection-page_wrapper_h1 {
      font-size: 40px;
    }
  }
}

@media screen and (max-width: 413px) {
  .matrix-selection-page_wrapper {
    .social-and-photo {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 59%;

      .img-hero {
        position: relative;
        width: 1310px;
        margin-right: -20px;

        &::before {
          display: block;
          width: 500px;
          content: "";
          //background-image: url('../../../assets/hero2.png');
          background-image: url('../../../assets/hero1.png');
          background-size: cover;
          position: absolute;
          top: -16vh;
          right: 0px;
          bottom: -50vh;
          left: -150px;
          overflow: hidden;
        }
      }

      .social-links_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        width: 400px;
        margin-top: 40px;

        .social-links {
          position: absolute;
          bottom: 28%;
          z-index: 1;
        }
      }
    }
  }
}