
.appointment-compatibility {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 45px;
  width: 50%;
  padding-top: 20px;

  h3 {
    font-family: 'Playfair Display', serif;
    font-size: 13px;
    color: #3a3a3a;
  }


  .life-map_wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 170px;

    .life-map_item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      width: 270px;

      .life-map_item-circle {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 30px;
        height: 30px;
        border: 1px solid #8696ff;
        border-radius: 50%;
        font-weight: normal;
        font-family: 'Playfair Display', serif;
        font-size: 19px;
        margin-left: 15px;
      }

      .circle-bg {
        border: none;
        background-color: #8696ff;
        color: white;
      }

      .circle-bg-dark {
        border: none;
        background-color: #8696ff;
        color: white;
      }

      .life-map_item-title {
        font-size: 13px;
        color: #114257;
      }
    }
  }
}

@media screen and (max-width: 1120px) {

  .appointment-compatibility {
    display: flex;
    flex-direction: column;
    gap: 0px;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 20px;
    //border: 1px solid green;
    margin: 0px auto 0px auto;
    //margin-bottom: 55px;

    .life-map_container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 5px;

      .life-map_wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;


        .life-map_item {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          width: 195px;

          .life-map_item-title {
            font-family: 'Playfair Display', serif;
            font-size: 10px;
          }
        }
      }
    }


  }
}