@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;1,700&family=Roboto:wght@300;400&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0px;
  color: #030303;
}

.container {
  margin: 0 auto;
  padding: 0 5px;
}

.main__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  min-height: 100vh;
  overflow-x: hidden;
  //background: #F5E9E4;
  background: white;
}


@media screen and (max-width: 800px) {

}