.matrix-year-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 170px;
  margin-top: -100px;
  width: 250px;

  table {
    border-collapse: collapse;
    letter-spacing: 0px;
    overflow: hidden;
    border-radius: 0px;
    width: 100%;

    th, td {
      border: 1px solid black;
      height: 50px;
      text-align: center;
      letter-spacing: 0px;
      font-family: 'Playfair Display', serif;
      color: black;
      font-weight: bold;
      font-size: 15px;
    }

    .th_xs {
      background-color: #C6C8D4;
    }

    .th_main {
      width: 44%;
    }
  }
}

@media screen and (max-width: 1325px) {
  .matrix-year-table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 0px;
    margin-top: 0px;
    width: 370px;

    table {
      border-collapse: collapse;
      letter-spacing: 0px;
      overflow: hidden;
      border-radius: 0px;
      width: 100%;

      th, td {
        border: 1px solid black;
        height: 30px;
        text-align: center;
        letter-spacing: 0px;
        font-family: 'Playfair Display', serif;
        color: black;
        font-weight: bold;
        font-size: 15px;
      }

      .th_xs {
        background-color: #C6C8D4;
      }

      .th_main {
        width: 44%;
      }
    }
  }
}