.matrix_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.matrix-and-big-table-wrapper {
  //border: 1px solid red;
  display: flex;
  flex-direction: row;
}

.table-wrapper {
  //border: 1px solid #00ff2a;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 150px;
  width: 100%;

  .table-wrapper_btn-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .table-wrapper_btn-group-p {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0px;

      p {
        text-align: center;
        margin: 5px 0;
        padding: 0;
        width: 390px;
        font-size: 16px;

      }
    }

  }

  .btn_compatibility-matrix {
    width: 250px;
    height: 45px;
    background-color: #8949a4;

    &:hover {
      background-color: rgba(17, 66, 87, 0.7);
    }
  }


}


h3 {
  text-align: center;
  margin-bottom: 15px;
}

@media screen and (max-width: 1325px) {
  .matrix_wrapper {
  }
  .matrix_container {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;

    .table-wrapper {
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .table-wrapper_btn-group {
        p {
          font-size: 13px;
          margin: 3px 0;
        }
      }
    }

    .matrix-and-big-table-wrapper {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}