
.matrixs_container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;


  .matrixs-wrapper {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 1360px) {
      flex-direction: column;
    }
  }

  .matrix-result {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    //border-top: 1px solid black;
    padding: 45px 11% 0;
    height: 650px;

    @media screen and (max-width: 1424px) {
      //padding: 45px 0% 0;
      padding: 45px 0% 0;
    }

    .purposes {
      //border: 1px solid red;
      position: absolute;
      left: 50%;
      top: 25%;
      width: 500px;

      .table-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      h3 {
        font-family: 'Playfair Display', serif;
        margin: 0;
        color: black;
      }

      .purpose_h3-description {
        font-size: 15px;
        color: #524a4a;
        margin: 5px 0;
        width: 300px;
      }


      .purposes_top {
        //border: 1px solid yellowgreen;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
        gap: 25px;

        .purpose {
          //border: 1px solid blue;

          .word {
            padding-left: 10px;
          }

          .circle_purpose {
            width: 30px;
            height: 30px;
            font-size: 19px;
          }

        }

      }

      .purpose_general {
        //border: 1px solid blue;
        height: 80px;
        margin-top: -5px;
        margin-bottom: -20px;

        .circle_solo {
          .circle {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            width: 30px;
            height: 30px;
            font-size: 18px;
            background: #EDE4FF;
            border: 1px solid black;
          }

        }
      }
    }

    .matrix-compatibility {
      min-height: 610px;
      height: 610px;
      overflow: visible;
      width: 600px;
    }
  }

  .btn_compatibility-matrix {
    width: 250px;
    height: 45px;
    background-color: #8949A4;

    &:hover {
      //background: -moz-linear-gradient(45deg, rgb(255, 231, 237) 0%, #f9dce1 25%, #bdbdf8 50%, #caeeec 75%, #caeeec 100%);
      //background: -webkit-linear-gradient(45deg, rgb(255, 231, 237) 0%, #f9dce1 25%, #bdbdf8 50%, #caeeec 75%, #caeeec 100%);
      //background: linear-gradient(45deg, rgb(255, 231, 237) 0%, #caeeec 25%, #caeeec 50%, #bdbdf8 75%, #f9dce1 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
      background: white;

    }
  }

  .btn-top {
    @media screen and (max-width: 800px) {
      margin-top: 90px;
    }
  }
}

@media screen and (max-width: 1366px) {
  .matrixs_container {
    .matrix-result {
      .matrix-compatibility {
        margin-left: 14%;
      }
    }
  }
}

@media screen and (max-width: 1360px) {
  .matrixs_container {
    .matrix-result {
      .matrix-compatibility {
        margin-left: -18%;
        //border: 1px solid green;
      }
    }
  }
}


@media screen and (max-width: 1120px) {

  .matrixs_container {
    .table-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 160px;
      gap: 10px;
    }

    .matrixs-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      @media screen and (max-width: 800px) {
        width: 460px;
      }

      .matrix-compatibility {
        //border: 1px solid violet;
        height: 700px;
      }
    }

    .matrix-result {
      display: flex;
      flex-direction: column;
      border-top: none;
      padding: 0px;


      @media screen and (max-width: 800px) {
        height: 650px;
        .matrix-compatibility {
          height: 300px;
          width: 380px;
          margin-left: 8.5%;
        }
        .purposes {
          width: 100%;
          position: relative;
          left: 0%;
          top: -1%;

          .table-wrapper {
            gap: 10px;
          }

          h3 {
            font-family: 'Playfair Display', serif;
            margin: 0;
            color: black;
          }

          .purpose_h3-description {
            //border: 1px solid red;
            font-size: 11px;
            margin: 5px 0;
            width: 185px;
            height: 70px;
          }

          .purposes_top {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px 0;
            gap: 5px;

            .purpose {
              //border: 1px solid blue;
              .circle_purpose {
                width: 30px;
                height: 30px;
                background: transparent;
                color: black;
              }

              .circle_sum {
                background: transparent;
                color: black;
              }
            }

          }

          .purpose_general {
            //border: 1px solid blue;
            height: 80px;
            margin-top: -5px;
            margin-bottom: -20px;

            .circle_solo {
              .circle {
                width: 30px;
                height: 30px;
                background: transparent;
                font-size: 18px;
                color: black;
              }
            }

          }
        }
      }

      .matrix-compatibility {
        min-height: 300px;
        overflow: visible;

        @media screen and (max-width: 800px) {
          min-height: 345px;
        }
      }
    }
  }
}
