* {
  box-sizing: border-box;
}

#matrix-compatibility {
  position: relative;
  height: 600px;
  width: 600px;
  margin-left: 22.3%;
  //border: 1px solid rebeccapurple;
  @media screen and (max-width: 1360px) {
    margin-left: 15.5%;
  }
}

.matrix-compatibility {
  position: relative;
  min-height: 900px;
  width: 600px;
  margin-bottom: 100px;
  overflow: visible;
  //border: 1px solid yellowgreen;

  @media screen and (max-width: 1360px) {
    #circle_soul {
      margin-top: -46px;
    }
  }
  @media screen and (max-width: 800px) {
    #circle_soul {
      margin-top: 0px;
    }
  }


  .purposes {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-left: 5.3%;
    //border: 1px solid red;


    @media screen and (max-width: 1360px) {
      margin-left: -1%;
    }

    h3 {
      font-family: 'Playfair Display', serif;
      margin: 0;
      color: black;;
    }

    .purpose_h3-description {
      font-size: 15px;
      color: #524a4a;
      margin: 5px 0;
      width: 300px;
    }

    .purposes_top {
      //border: 1px solid yellowgreen;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0;
      gap: 10px;

      .purpose {
        //border: 1px solid blue;
        .circle_purpose {
          width: 30px;
          height: 30px;
          font-size: 19px;

        }
      }

    }

    .purpose_general {
      //border: 1px solid blue;
      height: 80px;
      margin-top: -5px;
      margin-bottom: -20px;

      .circle_solo {
        .circle {
          width: 30px;
          height: 30px;
          background-color: transparent;
          border: 1px solid black;
          font-size: 18px;
        }

      }
    }
  }


  @media screen and (max-width: 1525px) {
    .matrix-compatibility {
      margin-bottom: 20px;
    }
  }

  h2 {
    width: 120px;
    position: absolute;
    top: -15px;
    left: calc(50% - (120px / 2));
    font-family: 'Playfair Display', serif;
    font-weight: normal;
  }

  #h3_life-map {
    width: 180px;
    position: absolute;
    left: calc(50% - (180px / 2));
    top: -15px;
    color: #3a3a3a;
    font-family: 'Playfair Display', serif;
    color: black;
    font-size: 18px;
  }

  .circle {
    display: flex;
    position: absolute;
    z-index: 999;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 2px solid #454545;
    font-weight: normal;
    background: white;
  }

  .circle_large {
    width: 60px;
    height: 60px;
    font-size: 36px;
  }

  .circle_large_inside {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 36px;
    width: 60px;
    height: 60px;
    border: none;
    color: white;
  }

  .circle_medium {
    width: 33px;
    height: 33px;
    font-size: 18px;
  }

  .circle_medium_inside {
    justify-content: center;
    align-items: flex-start;
    width: 33px;
    height: 33px;
    border: none;
    color: white;

  }

  .circle_small {
    width: 24px;
    height: 24px;
    font-size: 15px;
  }

  .circle_small_inside {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 24px;
    height: 24px;
    border: none;
    color: white;
  }

  .circle_large_not-inside, .circle_medium_not-inside, .circle_small_not-inside {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: white;
  }

  .circle_xs {
    width: 20px;
    height: 20px;
    color: black;

    border: none;
    font-size: 14px;
  }

  #large-center {
    top: 288px;
    left: 268px;
    border: 2px solid #F7DE74;
    background-color: #F7DE74;

  }

  #large-center_inside {
    background: #F7DE74;
  }

  #large-1 {
    top: 61px;
    left: 268px;
    border: 2px solid #8847A3;
    background-color: #8847A3;
  }

  #large-1_inside {
    background: #8847A3;
  }

  #large-2 {
    top: 124px;
    left: 105px;
  }

  #large-3 {
    top: 124px;
    left: 428px;
  }

  #large-4 {
    top: 285px;
    left: 40px;
    border: 2px solid #8847A3;
    background-color: #8847A3;
  }

  #large-4_inside {
    background: #8847A3;

  }

  #large-5 {
    top: 285px;
    left: 496px;
    border: 2px solid #EE4041;
    background-color: #EE4041;
  }

  #large-5_inside {
    background: #EE4041;

  }

  #large-6 {
    top: 448px;
    left: 105px;
  }

  #large-7 {
    top: 448px;
    left: 428px;
  }

  #large-8 {
    top: 513px;
    left: 268px;
    border: 2px solid #EE4041;
    background-color: #EE4041;
  }

  #large-8_inside {
    background: #EE4041;
  }

  hr {
    position: relative;
    border-top: 1px solid #808080;
  }

  #hr_top-left {
    -webkit-transform: rotate(158deg);
    -moz-transform: rotate(157deg);
    -ms-transform: rotate(157deg);
    -o-transform: rotate(157deg);
    width: 175px;
    top: 95px;
    left: 118px;
  }

  #hr_top-right {
    -webkit-transform: rotate(24deg);
    -moz-transform: rotate(24deg);
    -ms-transform: rotate(24deg);
    -o-transform: rotate(24deg);
    width: 175px;
    top: 95px;
    left: 297px;
  }

  #hr_top-left-2 {
    -webkit-transform: rotate(112deg);
    -moz-transform: rotate(112deg);
    -ms-transform: rotate(112deg);
    -o-transform: rotate(112deg);
    width: 175px;
    top: 230px;
    left: -13px;
  }

  #hr_top-right-2 {
    -webkit-transform: rotate(68deg);
    -moz-transform: rotate(68deg);
    -ms-transform: rotate(68deg);
    -o-transform: rotate(68deg);
    width: 175px;
    top: 228px;
    left: 430px;
  }

  #hr_bot-left-2 {
    -webkit-transform: rotate(68deg);
    -moz-transform: rotate(68deg);
    -ms-transform: rotate(68deg);
    -o-transform: rotate(68deg);
    width: 175px;
    top: 400px;
    left: -8px;
  }

  #hr_bot-right-2 {
    -webkit-transform: rotate(112deg);
    -moz-transform: rotate(112deg);
    -ms-transform: rotate(112deg);
    -o-transform: rotate(112deg);
    width: 175px;
    top: 400px;
    left: 425px;
  }

  #hr_bot-left {
    -webkit-transform: rotate(22deg);
    -moz-transform: rotate(22deg);
    -ms-transform: rotate(22deg);
    -o-transform: rotate(22deg);
    width: 175px;
    top: 526px;
    left: 120px;
  }

  #hr_bot-right {
    -webkit-transform: rotate(157deg);
    -moz-transform: rotate(157deg);
    -ms-transform: rotate(157deg);
    -o-transform: rotate(157deg);
    width: 175px;
    top: 522px;
    left: 300px;
  }

  #hr_center-vertical {
    position: relative;
    z-index: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    width: 388px;
    top: 315px;
    left: 103px;
  }

  #hr_center-horizontal {
    position: relative;
    z-index: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    width: 388px;
    top: 300px;
    left: 107px;
  }

  #large_center-circle {
    position: absolute;
    top: 182px;
    left: 248px;
    width: 385px;
    height: 385px;
    background: none;
  }

  .square {
    position: absolute;
    top: 165px;
    left: 145px;
    border: 2px solid #454545;
    width: 305px;
    height: 305px;
  }

  #square-2 {
    transform: rotate(45deg);
  }

  #medium-1 {
    top: 121px;
    left: 281px;
    border: 2px solid #526DFC;
    background-color: #526DFC;
  }

  #medium-1_inside {
    background: #526DFC;
  }

  #medium-2 {
    top: 172px;
    left: 151px;
  }

  #medium-3 {
    top: 171px;
    left: 410px;
  }

  #medium-4 {
    top: 301px;
    left: 100px;
    border: 2px solid #526DFC;
    background-color: #526DFC;
  }

  #medium-4_inside {
    background: #526DFC;
  }

  #medium-5 {
    top: 301px;
    left: 463px;
  }

  #medium-6 {
    top: 430px;
    left: 152px;
  }

  #medium-7 {
    top: 428px;
    left: 410px;
  }

  #medium-8 {
    top: 481px;
    left: 281px;
  }

  #small-1 {
    top: 154px;
    left: 284px;
    border: 2px solid #47CFDD;
    background-color: #47CFDD;
  }

  #small-1_inside {
    background: #47CFDD;
  }

  #small-2 {
    top: 197px;
    left: 177px;
  }

  #small-3 {
    top: 198px;
    left: 392px;
  }

  #small-4 {
    top: 198px;
    left: 286px;
    border: 2px solid #AFDE61;
    background-color: #AFDE61;

  }

  #small-4_inside {
    background: #AFDE61;
  }


  #small-5 {
    top: 306px;
    left: 135px;
    border: 2px solid #47CFDD;
    background-color: #47CFDD;
  }

  #small-5_inside {
    background: #47CFDD;
  }

  #small-6 {
    top: 306px;
    left: 190px;
    border: 2px solid #AFDE61;
    background-color: #AFDE61;
  }

  #small-6_inside {
    background: #AFDE61;
  }

  #small-7 {
    top: 306px;
    left: 437px;
    border: 2px solid #FF914D;
    background-color: #FF914D;
  }

  #small-7_inside {
    background: #FF914D;
  }

  #small-8 {
    top: 410px;
    left: 178px;
  }

  #small-9 {
    top: 409px;
    left: 331px;
  }

  #small-10 {
    top: 409px;
    left: 390px;
  }

  #small-11 {
    top: 378px;
    left: 361px;
  }

  #small-12 {
    top: 349px;
    left: 391px;
  }

  #small-13 {
    top: 458px;
    left: 286px;
    border: 2px solid #FF914D;
    background-color: #FF914D;
  }

  #small-13_inside {
    background: #FF914D;
  }

  #small-14 {
    display: none;
    top: 304px;
    left: 350px;

  }

  #small-14_inside {

  }

  #small-15 {
    display: none;
    top: 304px;
    left: 395px;
  }

  #xs-1 {
    top: 7px;
    left: 430px;
    background: #8847A3;
  }

  #xs-2 {
    top: 113px;
    left: 178px;
    background: black;

  }

  #xs-3 {
    top: 113px;
    left: 684.9px;
    background: black;

  }

  #xs-4 {
    top: 364px;
    left: 74px;
    background: #8847A3;

  }

  #xs-5 {
    top: 364px;
    left: 611px;
    background: #FF914D;

  }

  #xs-6 {
    top: 364px;
    left: 788px;
    background: #EE4041;

  }

  #xs-7 {
    top: 432px;
    left: 560px;
    background: black;

  }

  #xs-8 {
    top: 445px;
    left: 509px;
    background: black;

  }

  #xs-9 {
    top: 495px;
    left: 497px;
    background: black;

  }

  #xs-10 {
    top: 546px;
    left: 431px;
    background: #FF914D;

  }

  #xs-11 {
    top: 617px;
    left: 179px;
    background: black;

  }

  #xs-12 {
    top: 722px;
    left: 432px;
    background: #EE4041;

  }

  #xs-13 {
    top: 617px;
    left: 684px;
    background: black;

  }

  #xs-14 {
    top: 409px;
    left: 431px;
    background: #F7DE74;
    color: black;

  }

  .small-arrow {
    height: 8px;
    position: absolute;
  }

  .years {
    position: absolute;
    font-weight: bold;
    font-size: 11px;
    line-height: 11px;
  }

  .years_left {
    left: -70px;
    top: -59px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }

  .years_right {
    left: -70px;
    top: -2px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  #years-0 {
    top: 40px;
    left: 40px;
  }

  #years-10 {
    top: -28px;
    left: 72px;
  }

  #years-20 {
    top: 23px;
    left: -170px;
  }

  #years-30 {
    top: -7px;
    left: -213px;
  }

  #years-40 {
    top: -76px;
    left: -253px;
  }

  #years-50 {
    top: -130px;
    left: -213px;
  }

  #years-60 {
    top: 120px;
    left: 117px;
  }

  #years-70 {
    top: 95px;
    left: 77px;
  }

  .arrow-right {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  .arrow-left {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }

  #arrow-1 {
    top: 13px;
    left: 450px;
  }

  #arrow-2 {
    top: 119px;
    left: 703px;
  }

  #arrow-3 {
    top: 370px;
    left: 808px;
  }

  #arrow-4 {
    top: 624px;
    left: 703px;
  }

  #arrow-5 {
    top: 119px;
    left: 178px;
  }

  #arrow-6 {
    top: 370px;
    left: 74px;
  }

  #arrow-7 {
    top: 624px;
    left: 179px;
  }

  #arrow-8 {
    top: 730px;
    left: 432px;
  }

  #comfort-zone {
    position: absolute;
    top: 442px;
    left: 323px;
  }

  #hr_dashed {
    border-top: 1px dashed grey;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    width: 225px;
    top: 366px;
    left: 262px;
  }

  #hr_dashed-result {
    border-top: 1px dashed grey;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    width: 210px;
    top: 372px;
    left: 270px;
  }

  .arrow {
    position: absolute;
  }

  #arrow-top-red {
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    top: 235px;
    left: 558px;
  }

  #men-line {
    position: absolute;
    -webkit-transform: rotate(44.4deg);
    -moz-transform: rotate(44.4deg);
    -ms-transform: rotate(44.4deg);
    top: 244px;
    left: 119px;
    font-style: italic;
    font-size: 9px;
  }

  #women-line {
    position: absolute;
    -webkit-transform: rotate(315deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    top: 244px;
    left: 245px;
    font-style: italic;
    font-size: 9px;
  }

  #sky-line {
    position: absolute;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    top: 218px;
    left: 205px;
    font-style: italic;
    font-size: 10px;

  }

  #earth-line {
    position: absolute;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    top: 304px;
    left: 245px;
    font-style: italic;
    font-size: 10px;

  }

  #arrow-bot-red {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    top: 491px;
    left: 302px;
  }

  #arrow-top-blue {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    top: 235px;
    left: 302px;
  }

  #arrow-bot-blue {
    -webkit-transform: rotate(310deg);
    -moz-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    -o-transform: rotate(300deg);
    top: 492px;
    left: 560px;
  }

  #heart {
    position: absolute;
    top: 397px;
    left: 314px;
    width: 20px;
  }

  #dollar {
    position: absolute;
    top: 340px;
    left: 370px;
    width: 20px;
  }

  #hr_rotate-1 {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    width: 410px;
    top: 295px;
    left: 91px;
    border: 1px solid #8497fc;
  }

  #hr_rotate-2 {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    width: 410px;
    top: 295px;
    left: 91px;
    border: 1px solid #fd8585;
  }


  .dot {
    position: absolute;
    background-color: rgba(95, 95, 95, 0.87);
    width: 3px;
    height: 3px;

    .dot_inside {
      background-color: white;
      width: 2px;
      height: 2px;
      border-radius: 50%;

      .years-period {
        color: black;
        font-size: 10px;
        font-family: 'Playfair Display', serif;
        font-weight: bold;
      }
    }
  }

  .years-period, .energy-value {
    position: absolute;
    padding-left: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    width: 38px;
    color: rgba(0, 0, 0, 0.89);
    left: 4px;
    top: -1px;
    letter-spacing: 0.3px;
    font-family: 'Playfair Display', serif;
  }

  .years-period {
    display: none;
    font-family: 'Roboto', serif;
    font-size: 7px;
  }

  .years-period_center {

  }

  .energy-value {
    font-size: 13px;
    left: 0px;
    display: none;
    font-weight: normal;
  }

  .energy-value_center {
    font-weight: bold;
  }

  #dot-1 {
    top: 274px;
    left: 57px;

    .years-period {
      top: 0px;
      left: -2px;
      display: flex;
    }

    .energy-value {
      top: -15px;
      left: -32px;
      display: flex;
    }
  }

  #dot-2 {
    top: 258px;
    left: 64px;

    .years-period {
      top: 0px;
      left: -3px;
      display: flex;
    }

    .energy-value {
      top: -15px;
      left: -32px;
      display: flex;
    }
  }

  #dot-3 {
    top: 242px;
    left: 70px;

    .years-period {
      top: 0px;
      left: -3px;
      display: flex;
    }

    .energy-value {
      top: -15px;
      left: -32px;
      display: flex;
    }
  }

  #dot-4 {
    top: 225px;
    left: 76px;
    width: 4px;
    height: 4px;

    .years-period {
      top: 0px;
      left: 0px;
      display: flex;
    }

    .energy-value {
      top: -13px;
      left: -31px;
      display: flex;
    }
  }

  #dot-5 {
    top: 210px;
    left: 83px;

    .years-period {
      top: 0px;
      left: -2px;
      display: flex;
    }

    .energy-value {
      top: -15px;
      left: -31px;
      display: flex;
    }
  }

  #dot-6 {
    top: 195px;
    left: 89px;

    .years-period {
      top: 0px;
      left: -2px;
      display: flex;
    }

    .energy-value {
      top: -15px;
      left: -31px;
      display: flex;
    }
  }

  #dot-7 {
    top: 178px;
    left: 96px;

    .years-period {
      top: 0px;
      left: -2px;
      display: flex;
    }

    .energy-value {
      top: -15px;
      left: -31px;
      display: flex;
    }
  }

  #dot-8 {
    top: 117px;
    left: 150px;

    .years-period {
      top: 6px;
      left: -4px;
      display: flex;
    }

    .energy-value {
      top: -16px;
      left: -31px;
      display: flex;
    }
  }

  #dot-9 {
    top: 111px;
    left: 165px;

    .years-period {
      top: 4px;
      left: -7px;
      display: flex;
    }

    .energy-value {
      top: -16px;
      left: -31px;
      display: flex;
    }
  }

  #dot-10 {
    top: 104px;
    left: 182px;

    .years-period {
      top: 3px;
      left: -7px;
      display: flex;
    }

    .energy-value {
      top: -16px;
      left: -29px;
      display: flex;
    }
  }

  #dot-11 {
    top: 97px;
    left: 198px;
    width: 4px;
    height: 4px;

    .years-period {
      top: 4px;
      left: 0px;
      display: flex;
    }

    .energy-value {
      top: -20px;
      left: -25px;
      display: flex;
    }
  }

  #dot-12 {
    top: 92px;
    left: 213px;

    .years-period {
      top: 4px;
      left: -8px;
      display: flex;
    }

    .energy-value {
      top: -22px;
      left: -19px;
      display: flex;
    }
  }

  #dot-13 {
    top: 86px;
    left: 227px;

    .years-period {
      top: 2px;
      left: -7px;
      display: flex;
    }

    .energy-value {
      top: -23px;
      left: -15px;
      display: flex;
    }
  }

  #dot-14 {
    top: 79px;
    left: 244px;

    .years-period {
      top: 2px;
      left: -7px;
      display: flex;
    }

    .energy-value {
      top: -22px;
      left: -15px;
      display: flex;
    }
  }

  #dot-15 {
    top: 79px;
    left: 344px;

    .years-period {
      top: 4px;
      left: -23px;
      display: flex;
    }

    .energy-value {
      top: -23px;
      left: -23px;
      display: flex;
    }
  }

  #dot-16 {
    top: 85px;
    left: 358px;

    .years-period {
      top: 5px;
      left: -23px;
      display: flex;
    }

    .energy-value {
      top: -22px;
      left: -20px;
      display: flex;
    }
  }

  #dot-17 {
    top: 91px;
    left: 371px;

    .years-period {
      top: 6px;
      left: -22px;
      display: flex;
    }

    .energy-value {
      top: -22px;
      left: -16px;
      display: flex;
    }
  }

  #dot-18 {
    top: 97px;
    left: 386px;
    width: 4px;
    height: 4px;

    .years-period {
      top: 4px;
      left: -48px;
      display: flex;
    }

    .energy-value {
      top: -20px;
      left: -12px;
      display: flex;
    }
  }

  #dot-19 {
    top: 103px;
    left: 400px;

    .years-period {
      top: 7px;
      left: -22px;
      display: flex;
    }

    .energy-value {
      top: -18px;
      left: -8px;
      display: flex;
    }
  }

  #dot-20 {
    top: 110px;
    left: 415px;

    .years-period {
      top: 7px;
      left: -22px;
      display: flex;
    }

    .energy-value {
      top: -17px;
      left: -7px;
      display: flex;
    }
  }

  #dot-21 {
    top: 117px;
    left: 430px;

    .years-period {
      top: 7px;
      left: -22px;
      display: flex;
    }

    .energy-value {
      top: -14px;
      left: -4px;
      display: flex;
    }
  }

  #dot-22 {
    top: 172px;
    left: 492px;

    .years-period {
      top: 8px;
      left: -30px;
      display: flex;
    }

    .energy-value {
      top: -16px;
      left: -7px;
      display: flex;
    }
  }

  #dot-23 {
    top: 186px;
    left: 497px;

    .years-period {
      top: 8px;
      left: -30px;
      display: flex;
    }

    .energy-value {
      top: -16px;
      left: -7px;
      display: flex;
    }
  }

  #dot-24 {
    top: 202px;
    left: 504px;

    .years-period {
      top: 7px;
      left: -30px;
      display: flex;
    }

    .energy-value {
      top: -16px;
      left: -7px;
      display: flex;
    }
  }

  #dot-25 {
    top: 219px;
    left: 510px;
    width: 4px;
    height: 4px;

    .years-period {
      top: 1px;
      left: -52px;
      display: flex;
    }

    .energy-value {
      top: -14px;
      left: -6px;
      display: flex;
    }
  }

  #dot-26 {
    top: 237px;
    left: 518px;

    .years-period {
      top: 1px;
      left: -34px;
      display: flex;
    }

    .energy-value {
      top: -12px;
      left: -6px;
      display: flex;
    }
  }

  #dot-27 {
    top: 255px;
    left: 525px;

    .years-period {
      top: 1px;
      left: -34px;
      display: flex;
    }

    .energy-value {
      top: -12px;
      left: -6px;
      display: flex;
    }
  }

  #dot-28 {
    top: 272px;
    left: 532px;

    .years-period {
      top: 1px;
      left: -34px;
      display: flex;
    }

    .energy-value {
      top: -12px;
      left: -6px;
      display: flex;
    }
  }

  #dot-29 {
    top: 347px;
    left: 537px;

    .years-period {
      top: 1px;
      left: -34px;
      display: flex;
    }

    .energy-value {
      top: -9px;
      left: -4px;
      display: flex;
    }
  }

  #dot-30 {
    top: 364px;
    left: 530px;

    .years-period {
      top: 1px;
      left: -34px;
      display: flex;
    }

    .energy-value {
      top: -9px;
      left: -3px;
      display: flex;
    }
  }

  #dot-31 {
    top: 380px;
    left: 524px;

    .years-period {
      top: 1px;
      left: -34px;
      display: flex;
    }

    .energy-value {
      top: -9px;
      left: -2px;
      display: flex;
    }
  }

  #dot-32 {
    top: 396px;
    left: 516px;
    width: 4px;
    height: 4px;

    .years-period {
      top: -4px;
      left: -56px;
      display: flex;
    }

    .energy-value {
      top: -9px;
      left: -3px;
      display: flex;
    }
  }

  #dot-33 {
    top: 411px;
    left: 511px;

    .years-period {
      top: -1px;
      left: -35px;
      display: flex;
    }

    .energy-value {
      top: -9px;
      left: -4px;
      display: flex;
    }
  }

  #dot-34 {
    top: 426px;
    left: 505px;

    .years-period {
      top: -1px;
      left: -35px;
      display: flex;
    }

    .energy-value {
      top: -3px;
      left: -4px;
      display: flex;
    }
  }

  #dot-35 {
    top: 443px;
    left: 498px;

    .years-period {
      top: -1px;
      left: -35px;
      display: flex;
    }

    .energy-value {
      top: -3px;
      left: -7px;
      display: flex;
    }
  }

  #dot-36 {
    top: 510px;
    left: 448px;

    .years-period {
      top: -6px;
      left: -35px;
      display: flex;
    }

    .energy-value {
      top: -3px;
      left: -7px;
      display: flex;
    }
  }

  #dot-37 {
    top: 516px;
    left: 434px;

    .years-period {
      top: -6px;
      left: -35px;
      display: flex;
    }

    .energy-value {
      top: -3px;
      left: -7px;
      display: flex;
    }
  }

  #dot-38 {
    top: 522px;
    left: 419px;

    .years-period {
      top: -6px;
      left: -35px;
      display: flex;
    }

    .energy-value {
      top: -3px;
      left: -7px;
      display: flex;
    }
  }

  #dot-39 {
    top: 528px;
    left: 404px;
    width: 4px;
    height: 4px;

    .years-period {
      top: -9px;
      left: -60px;
      display: flex;
    }

    .energy-value {
      top: -3px;
      left: -7px;
      display: flex;
    }
  }

  #dot-40 {
    top: 536px;
    left: 387px;

    .years-period {
      top: -4px;
      left: -35px;
      display: flex;
    }

    .energy-value {
      top: -3px;
      left: -7px;
      display: flex;
    }
  }

  #dot-41 {
    top: 542px;
    left: 371px;

    .years-period {
      top: -4px;
      left: -35px;
      display: flex;
    }

    .energy-value {
      top: -3px;
      left: -7px;
      display: flex;
    }
  }

  #dot-42 {
    top: 549px;
    left: 355px;

    .years-period {
      top: -5px;
      left: -35px;
      display: flex;
    }

    .energy-value {
      top: -3px;
      left: -7px;
      display: flex;
    }
  }

  #dot-43 {
    top: 551px;
    left: 241px;

    .years-period {
      top: -7px;
      left: -5px;
      display: flex;
    }

    .energy-value {
      top: 0px;
      left: -28px;
      display: flex;
    }
  }

  #dot-44 {
    top: 545px;
    left: 227px;

    .years-period {
      top: -7px;
      left: -5px;
      display: flex;
    }

    .energy-value {
      top: 0px;
      left: -28px;
      display: flex;
    }
  }

  #dot-45 {
    top: 539px;
    left: 212px;

    .years-period {
      top: -8px;
      left: -5px;
      display: flex;
    }

    .energy-value {
      top: 0px;
      left: -28px;
      display: flex;
    }
  }

  #dot-46 {
    top: 534px;
    left: 198px;
    height: 4px;
    width: 4px;

    .years-period {
      top: -15px;
      left: -6px;
      display: flex;
    }

    .energy-value {
      top: 0px;
      left: -28px;
      display: flex;
    }
  }

  #dot-47 {
    top: 527px;
    left: 181px;

    .years-period {
      top: -9px;
      left: -7px;
      display: flex;
    }

    .energy-value {
      top: 0px;
      left: -28px;
      display: flex;
    }
  }

  #dot-48 {
    top: 521px;
    left: 166px;

    .years-period {
      top: -10px;
      left: -7px;
      display: flex;
    }

    .energy-value {
      top: 0px;
      left: -28px;
      display: flex;
    }
  }

  #dot-49 {
    top: 513px;
    left: 148px;

    .years-period {
      top: -8px;
      left: -7px;
      display: flex;
    }

    .energy-value {
      top: 0px;
      left: -28px;
      display: flex;
    }
  }

  #dot-50 {
    top: 451px;
    left: 96px;

    .years-period {
      top: -10px;
      left: -0px;
      display: flex;
    }

    .energy-value {
      top: 0px;
      left: -28px;
      display: flex;
    }
  }

  #dot-51 {
    top: 438px;
    left: 91px;

    .years-period {
      top: -10px;
      left: -1px;
      display: flex;
    }

    .energy-value {
      top: -3px;
      left: -32px;
      display: flex;
    }
  }

  #dot-52 {
    top: 423px;
    left: 85px;

    .years-period {
      top: -10px;
      left: -1px;
      display: flex;
    }

    .energy-value {
      top: -7px;
      left: -32px;
      display: flex;
    }
  }

  #dot-53 {
    top: 407px;
    left: 78px;
    width: 4px;
    height: 4px;

    .years-period {
      top: -11px;
      left: -0px;
      display: flex;
    }

    .energy-value {
      top: -6px;
      left: -31px;
      display: flex;
    }
  }

  #dot-54 {
    top: 391px;
    left: 72px;

    .years-period {
      top: -8px;
      left: -0px;
      display: flex;
    }

    .energy-value {
      top: -7px;
      left: -31px;
      display: flex;
    }
  }

  #dot-55 {
    top: 375px;
    left: 66px;

    .years-period {
      top: -9px;
      left: -1px;
      display: flex;
    }

    .energy-value {
      top: -9px;
      left: -31px;
      display: flex;
    }
  }

  #dot-56 {
    top: 359px;
    left: 59px;

    .years-period {
      top: -10px;
      left: -1px;
      display: flex;
    }

    .energy-value {
      top: -12px;
      left: -31px;
      display: flex;
    }
  }

  .matrix-appointment {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    width: 100%;

    h3 {
      color: #3a3a3a;
    }

    .life-map_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 5px;


      .life-map_items {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .life-map_item {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          width: 175px;
          margin-top: 5px;

          .life-map_item-circle {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            width: 25px;
            height: 25px;
            border: 1px solid #8696ff;
            border-radius: 50%;
            font-weight: normal;
            font-size: 15px;
            font-family: 'Playfair Display', serif;

          }

          .circle-bg {
            border: none;
            background-color: #8696ff;
            color: white;
          }

          .circle-bg-dark {
            border: none;
            background-color: #8696ff;
            color: white;
          }

          .life-map_item-title {
            font-size: 10px;
            color: #114257;
          }
        }
      }
    }


  }

}
