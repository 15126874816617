* {
  box-sizing: border-box;
}

.matrix_wrapper {
  position: relative;
  height: 750px;
  width: 900px;
  margin-bottom: 100px;
  margin-left: 0px;
  //background: radial-gradient(circle, rgba(226,225,224,1) 0%, rgba(255,255,255,1) 100%);
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 1;
}

@media screen and (max-width: 1525px) {
  .matrix_wrapper {
    margin-bottom: 20px;
  }
}


.matrix-integrity_wrapper {
  $violet-light: #FFDFFF;
  $violet-dark: #DCB8FF;
  $blue-dark: #1F75FF;
  $blue-light: #BFF5FF;
  $green: #75ecaa;
  $yellow: #F5F8A7;
  $orange: #FCDCAA;
  $red-light: #FFB6B6;
  $red-dark: #ef5e4d;

  $large-circle-size: 70px;
  $large-circle-inside-size: 62.5px;
  $medium-circle-size: 47px;
  $medium-circle-inside-size: 39.9px;
  $small-circle-size: 47px;
  $small-circle-inside-size: 39.9px;
  $small-circle-not-inside-size: 43px;

  .circle {
    display: flex;
    position: absolute;
    z-index: 999;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 2px solid #454545;
    font-weight: normal;
    background: #F4F4F3;
  }

  .circle_large {
    width: $large-circle-size;
    height: $large-circle-size;
    font-size: 40px;
    font-family: 'Playfair Display', serif;
    background: white;
  }

  .circle_large_inside {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 40px;
    width: $large-circle-inside-size;
    height: $large-circle-inside-size;
    border: none;
    font-family: 'Playfair Display', serif;
    color: black;
  }

  .circle_medium {
    font-family: 'Playfair Display', serif;
    width: $medium-circle-size;
    height: $medium-circle-size;
    font-size: 25px;
    background: white;
  }

  .circle_medium_inside {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-family: 'Playfair Display', serif;
    width: $medium-circle-inside-size;
    height: $medium-circle-inside-size;
    border: none;
    color: black;
  }

  .circle_small {
    width: $small-circle-size;
    height: $small-circle-size;
    font-size: 25px;
    color: black;
    background: white;
  }

  .circle_small_inside {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-family: 'Playfair Display', serif;
    width: $small-circle-inside-size;
    height: $small-circle-inside-size;
    border: none;
    color: black;

  }

  .circle_large_not-inside, .circle_medium_not-inside, .circle_small_not-inside {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .circle_small_not-inside {
    box-sizing: content-box;
    align-items: flex-start;
    font-family: 'Playfair Display', serif;
    width: $small-circle-not-inside-size;
    height: $small-circle-not-inside-size;
  }

  .circle_xs {
    display: none;
    width: 20px;
    height: 20px;
    color: white;
    border: none;
    font-size: 14px;
  }

  #large-center {
    top: 340px;
    left: 407px;
    border: 2px solid $green;
    background-color: $green;

  }

  #large-center_inside {
    background: $green;
    color: black;
  }

  #large-1 {
    top: 26px;
    left: 407px;
    border: 2px solid $violet-light;
    background-color: $violet-light;
  }

  #large-1_inside {
    background: $violet-light;
    color: black;
  }

  #large-2 {
    top: 118px;
    left: 185px;
  }

  #large-3 {
    top: 118px;
    left: 628px;
  }

  #large-4 {
    top: 340px;
    left: 93px;
    border: 2px solid $violet-light;
    background-color: $violet-light;
  }

  #large-4_inside {
    background: $violet-light;
    //color: white;

  }

  #large-5 {
    top: 340px;
    left: 719px;
    border: 2px solid $red-dark;
    background-color: $red-dark;
  }

  #large-5_inside {
    background: $red-dark;
    //color: white;

  }

  #large-6 {
    top: 561px;
    left: 185px;
  }

  #large-7 {
    top: 561px;
    left: 628px;
  }

  #large-8 {
    top: 653px;
    left: 407px;
    border: 2px solid $red-dark;
    background-color: $red-dark;
  }

  #large-8_inside {
    background: $red-dark;
    //color: white;
  }

  hr {
    position: relative;
    border-top: 2px solid rgba(0, 0, 0, 0.58);
  }

  #hr_top-left {
    -webkit-transform: rotate(157deg);
    -moz-transform: rotate(157deg);
    -ms-transform: rotate(157deg);
    -o-transform: rotate(157deg);
    width: 250px;
    top: 80px;
    left: 183px;
  }

  #hr_top-right {
    -webkit-transform: rotate(24deg);
    -moz-transform: rotate(24deg);
    -ms-transform: rotate(24deg);
    -o-transform: rotate(24deg);
    width: 250px;
    top: 80px;
    left: 448px;
  }

  #hr_top-left-2 {
    -webkit-transform: rotate(112deg);
    -moz-transform: rotate(112deg);
    -ms-transform: rotate(112deg);
    -o-transform: rotate(112deg);
    width: 250px;
    top: 250px;
    left: 18px;
  }

  #hr_top-right-2 {
    -webkit-transform: rotate(68deg);
    -moz-transform: rotate(68deg);
    -ms-transform: rotate(68deg);
    -o-transform: rotate(68deg);
    width: 250px;
    top: 250px;
    left: 615px;
  }

  #hr_bot-left-2 {
    -webkit-transform: rotate(68deg);
    -moz-transform: rotate(68deg);
    -ms-transform: rotate(68deg);
    -o-transform: rotate(68deg);
    width: 235px;
    top: 495px;
    left: 31px;
  }

  #hr_bot-right-2 {
    -webkit-transform: rotate(112deg);
    -moz-transform: rotate(112deg);
    -ms-transform: rotate(112deg);
    -o-transform: rotate(112deg);
    width: 235px;
    top: 493px;
    left: 615px;
  }

  #hr_bot-left {
    -webkit-transform: rotate(23deg);
    -moz-transform: rotate(22deg);
    -ms-transform: rotate(22deg);
    -o-transform: rotate(22deg);
    width: 250px;
    top: 650px;
    left: 185px;
  }

  #hr_bot-right {
    -webkit-transform: rotate(157deg);
    -moz-transform: rotate(157deg);
    -ms-transform: rotate(157deg);
    -o-transform: rotate(157deg);
    width: 250px;
    top: 650px;
    left: 438px;
  }

  #hr_center-vertical {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    width: 387px;
    top: 349px;
    left: 247px;
  }

  #hr_center-horizontal {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    width: 388px;
    top: 349px;
    left: 247px;
  }

  #large_center-circle {
    position: absolute;
    top: 182px;
    left: 248px;
    width: 385px;
    height: 385px;
    background: none;
  }

  .square {
    position: absolute;
    top: 165px;
    left: 231px;
    border: 2px solid #454545;
    width: 420px;
    height: 420px;
  }

  #square-2 {
    transform: rotate(45deg);
  }

  #medium-1 {
    top: 96px;
    left: 418px;
    border: 2px solid $violet-dark;
    background-color: $violet-dark;
  }

  #medium-1_inside {
    background: $violet-dark;
  }

  #medium-2 {
    top: 172px;
    left: 238px;
  }

  #medium-3 {
    top: 172px;
    left: 597px;
  }

  #medium-4 {
    top: 351px;
    left: 163px;
    border: 2px solid $violet-dark;
    background-color: $violet-dark;
  }

  #medium-4_inside {
    background: $violet-dark;
  }

  #medium-5 {
    top: 351px;
    left: 672px;
    background: $red-light;
    border: 2px solid $red-light;
  }
  #medium-5_inside {
    background: $red-light;
  }

  #medium-6 {
    top: 530px;
    left: 239px;
  }

  #medium-7 {
    top: 531px;
    left: 597px;
  }

  #medium-8 {
    top: 606px;
    left: 418px;
    border: 2px solid $red-light;
    background-color: $red-light;
  }

  #medium-8_inside {
    background: $red-light;
  }

  #small-1 {
    top: 143px;
    left: 418px;
    border: 2px solid $blue-dark;
    background-color: $blue-dark;
  }

  #small-1_inside {
    background: $blue-dark;
  }

  #small-2 {
    top: 207px;
    left: 274px;
  }

  #small-3 {
    top: 206px;
    left: 564px;
  }

  #small-4 {
    top: 238px;
    left: 418px;
    border: 2px solid $blue-light;
    background-color: $blue-light;

  }

  #small-4_inside {
    background: $blue-light;
  }


  #small-5 {
    top: 351px;
    left: 210px;
    border: 2px solid $blue-dark;
    background-color: $blue-dark;
  }

  #small-5_inside {
    background: $blue-dark;
  }

  #small-6 {
    top: 351px;
    left: 297px;
    border: 2px solid $blue-light;
    background-color: $blue-light;
  }

  #small-6_inside {
    background: $blue-light;
  }

  #small-7 {
    top: 352px;
    left: 625px;
    border: 2px solid $orange;
    background-color: $orange;
  }

  #small-7_inside {
    background: $orange;
  }

  #small-8 {
    top: 498px;
    left: 273px;
  }

  #small-9 {
    top: 501px;
    left: 478px;
    width: 36px;
    height: 36px;
    font-size: 21px;
  }

  #small-10 {
    top: 500px;
    left: 567px;
  }

  #small-11 {
    top: 458px;
    left: 524px;
    width: 36px;
    height: 36px;
    font-size: 21px;
  }

  #small-12 {
    top: 413px;
    left: 568px;
    width: 36px;
    height: 36px;
    font-size: 21px;
  }

  #small-13 {
    top: 559px;
    left: 418px;
    border: 2px solid $orange;
    background-color: $orange;
  }

  #small-13_inside {
    background: $orange;
  }

  #small-14 {
    top: 352px;
    left: 539px;
    border: 2px solid $yellow;
    background: $yellow;
  }

  #small-14_inside {
    background: $yellow;
    color: black;
  }

  #small-15 {
    top: 464px;
    left: 419px;
    border: 2px solid $yellow;
    background: $yellow;
  }

  #small-15_inside {
    background: $yellow;
    color: black;
  }

  #small-16 {
    width: 30px;
    height: 30px;
    top: 548px;
    left: 480px;

  }
  #small-16_inside {
    background: white;
    width: 26px;
    height: 26px;
    color: black;
    font-size: 17px;
  }

  #small-17 {
    width: 30px;
    height: 30px;
    top: 505px;
    left: 525px;
  }
  #small-17_inside {
    background: white;
    width: 26px;
    height: 26px;
    color: black;
    font-size: 17px;
  }

  #small-18 {
    width: 30px;
    height: 30px;
    top: 461px;
    left: 571px;
  }
  #small-18_inside {
    background: white;
    width: 26px;
    height: 26px;
    color: black;
    font-size: 17px;
  }

  #small-19 {
    width: 30px;
    height: 30px;
    top: 413px;
    left: 616px;
  }
  #small-19_inside {
    background: white;
    width: 26px;
    height: 26px;
    color: black;
    font-size: 17px;
  }

  #xs-1 {
    top: 7px;
    left: 431px;
    background: $violet-light;
  }

  #xs-2 {
    top: 113px;
    left: 178px;
    background: black;

  }

  #xs-3 {
    top: 113px;
    left: 684.9px;
    background: black;

  }

  #xs-4 {
    top: 364px;
    left: 74px;
    background: $violet-light;

  }

  #xs-5 {
    top: 366px;
    left: 611px;
    background: $orange;

  }

  #xs-6 {
    top: 364px;
    left: 788px;
    background: $red-dark;

  }

  #xs-7 {
    top: 432px;
    left: 560px;
    background: black;

  }

  #xs-8 {
    top: 445px;
    left: 509px;
    background: black;

  }

  #xs-9 {
    top: 495px;
    left: 497px;
    background: black;

  }

  #xs-10 {
    top: 546px;
    left: 431px;
    background: $orange;

  }

  #xs-11 {
    top: 617px;
    left: 179px;
    background: black;

  }

  #xs-12 {
    top: 722px;
    left: 432px;
    background: $red-dark;

  }

  #xs-13 {
    top: 617px;
    left: 684px;
    background: black;

  }

  #xs-14 {
    top: 409px;
    left: 431px;
    background: $yellow;
    color: black;

  }

  .small-arrow {
    height: 8px;
    position: absolute;
  }

  .years {
    position: absolute;
    font-weight: bold;
    font-size: 11px;
    width: 35px;
  }

  .years_left {
    left: -70px;
    top: -59px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }

  .years_right {
    left: -70px;
    top: -2px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  .arrow-right {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  .arrow-left {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }

  #years-0 {
    top: -30px;
    left: -13px;
  }

  #years-10 {
    top: -32px;
    left: -5px;
  }

  #years-20 {
    top: -20px;
    left: -27px;
  }

  #years-30 {
    top: -5px;
    left: -4px;
  }

  #years-40 {
    top: -15px;
    left: -13px;
  }

  #years-50 {
    top: -5px;
    left: -20px;
  }

  #years-60 {
    top: -32px;
    left: -28px;
  }

  #years-70 {
    top: -32px;
    left: -20px;
  }

  #arrow-1 {
    top: 13px;
    left: 450px;
  }

  #arrow-2 {
    top: 119px;
    left: 703px;
  }

  #arrow-3 {
    top: 370px;
    left: 808px;
  }

  #arrow-4 {
    top: 624px;
    left: 703px;
  }

  #arrow-5 {
    top: 119px;
    left: 178px;
  }

  #arrow-6 {
    top: 370px;
    left: 74px;
  }

  #arrow-7 {
    top: 624px;
    left: 179px;
  }

  #arrow-8 {
    top: 730px;
    left: 432px;
  }

  #comfort-zone {
    display: none;
    position: absolute;
    top: 442px;
    left: 323px;
  }

  #hr_dashed {
    border-top: 1px dashed grey;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    width: 303px;
    top: 456px;
    left: 382px;
  }

  .arrow {
    position: absolute;
  }

  #arrow-top-red {
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    top: 235px;
    left: 558px;
  }

  #men-line {
    position: absolute;
    -webkit-transform: rotate(44.4deg);
    -moz-transform: rotate(44.4deg);
    -ms-transform: rotate(44.4deg);
    top: 276px;
    left: 241px;
    font-style: italic;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
  }

  #women-line {
    position: absolute;
    -webkit-transform: rotate(315deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    top: 276px;
    left: 407px;
    font-style: italic;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;

  }

  #sky-line {
    position: absolute;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    top: 265px;
    left: 347px;
    font-style: italic;
  }

  #earth-line {
    position: absolute;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    top: 360px;
    left: 458px;
    font-style: italic;
  }

  #arrow-bot-red {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    top: 491px;
    left: 302px;
  }

  #arrow-top-blue {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    top: 235px;
    left: 302px;
  }

  #arrow-bot-blue {
    -webkit-transform: rotate(310deg);
    -moz-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    -o-transform: rotate(300deg);
    top: 492px;
    left: 560px;
  }

  #heart {
    position: absolute;
    top: 468px;
    left: 460px;
    width: 30px;
  }

  #dollar {
    position: absolute;
    top: 398px;
    left: 538px;
    width: 30px;
  }

  #hr_rotate-1 {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    width: 590px;
    top: 340px;
    left: 142px;
    border: 1px solid #8497fc;
  }

  #hr_rotate-2 {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    width: 590px;
    top: 343px;
    left: 142px;
    border: 1px solid #fd8585;
  }


  .dot {
    position: absolute;
    background-color: #454545;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .dot_inside {
      background-color: white;
      width: 4px;
      height: 4px;
      border-radius: 50%;

      .years-period {
        color: black;
        font-size: 10px;
        min-width: 60px;
      }
    }

    .years-period, .energy-value {
      position: absolute;
      padding-left: 1px;
      //font-family: 'Playfair Display', serif;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 8px;
      width: 38px;
      color: rgba(0, 0, 0, 0.73);
      left: 4px;
      top: -1px;
      letter-spacing: 0.5px;
    }

    .energy-value {
      font-family: 'Playfair Display', serif;
      font-size: 16px;
      left: -32px;
      top: -11px;
      font-weight: normal;
    }
  }

  #dot-1 {
    top: 320px;
    left: 115px;

    .years-period {
      top: 2px;
      left: 0px;
    }
  }

  #dot-2 {
    top: 295px;
    left: 125px;

    .years-period {
      top: 2px;
      left: 0px;
    }
  }

  #dot-3 {
    top: 273px;
    left: 134px;

    .years-period {
      top: 2px;
      left: 0px;
    }
  }

  #dot-4 {
    top: 246px;
    left: 143px;
    width: 8px;
    height: 8px;

    .years-period {
      left: 8px;
      top: 2px;
    }
  }

  #dot-5 {
    top: 222px;
    left: 155px;

    .years-period {
      top: 2px;
      left: 0px;
    }
  }

  #dot-6 {
    top: 198px;
    left: 164px;

    .years-period {
      top: 2px;
      left: 0px;
    }
  }

  #dot-7 {
    top: 172px;
    left: 175px;

    .years-period {
      top: 9px;
      left: -2px;
    }
  }

  #dot-8 {
    top: 105px;
    left: 245px;

    .years-period {
      top: 9px;
      left: -10px;
    }

    .energy-value {
      top: -23px;
      left: -22px;
    }
  }

  #dot-9 {
    top: 95px;
    left: 270px;

    .years-period {
      top: 9px;
      left: -10px;
    }

    .energy-value {
      top: -23px;
      left: -22px;
    }
  }

  #dot-10 {
    top: 84px;
    left: 294px;

    .years-period {
      top: 9px;
      left: -10px;
    }

    .energy-value {
      top: -23px;
      left: -22px;
    }
  }

  #dot-11 {
    top: 71px;
    left: 319px;
    width: 8px;
    height: 8px;

    .years-period {
      top: 12px;
      left: -1px;
      width: 50px;
    }

    .energy-value {
      top: -23px;
      left: -22px;
    }
  }

  #dot-12 {
    top: 63px;
    left: 344px;

    .years-period {
      top: 9px;
      left: -10px;
    }

    .energy-value {
      top: -23px;
      left: -22px;
    }
  }

  #dot-13 {
    top: 53px;
    left: 367px;

    .years-period {
      top: 9px;
      left: -11px;
    }

    .energy-value {
      top: -23px;
      left: -22px;
    }
  }

  #dot-14 {
    top: 42px;
    left: 393px;

    .years-period {
      top: 10px;
      left: -18px;
    }

    .energy-value {
      top: -23px;
      left: -22px;
    }
  }

  #dot-15 {
    top: 43px;
    left: 485px;

    .years-period {
      top: 11px;
      left: -12px;
    }

    .energy-value {
      top: -22px;
      left: -5px;
    }
  }

  #dot-16 {
    top: 55px;
    left: 511px;

    .years-period {
      top: 10px;
      left: -19px;
    }

    .energy-value {
      top: -22px;
      left: -5px;
    }
  }

  #dot-17 {
    top: 65px;
    left: 535px;

    .years-period {
      top: 10px;
      left: -19px;
    }

    .energy-value {
      top: -22px;
      left: -5px;
    }
  }

  #dot-18 {
    top: 75px;
    left: 559px;
    width: 8px;
    height: 8px;

    .years-period {
      top: 11px;
      left: -31px;
      width: 50px;
    }

    .energy-value {
      top: -22px;
      left: -5px;
    }
  }

  #dot-19 {
    top: 88px;
    left: 585px;

    .years-period {
      top: 10px;
      left: -19px;
    }

    .energy-value {
      top: -22px;
      left: -5px;
    }
  }

  #dot-20 {
    top: 98px;
    left: 608px;

    .years-period {
      top: 10px;
      left: -19px;
    }

    .energy-value {
      top: -22px;
      left: -5px;
    }
  }

  #dot-21 {
    top: 110px;
    left: 635px;

    .years-period {
      top: 10px;
      left: -29px;
    }

    .energy-value {
      top: -22px;
      left: -5px;
    }
  }

  #dot-22 {
    top: 178px;
    left: 706px;

    .years-period {
      top: 6px;
      left: -35px;
    }

    .energy-value {
      top: -20px;
      left: -5px;
    }
  }

  #dot-23 {
    top: 200px;
    left: 715px;

    .years-period {
      top: 3px;
      left: -38px;
    }

    .energy-value {
      top: -20px;
      left: -5px;
    }
  }

  #dot-24 {
    top: 223px;
    left: 724px;

    .years-period {
      top: 2px;
      left: -38px;
    }

    .energy-value {
      top: -20px;
      left: -5px;
    }
  }

  #dot-25 {
    top: 246px;
    left: 733px;
    width: 8px;
    height: 8px;

    .years-period {
      top: 2px;
      left: -58px;
      width: 50px;
    }

    .energy-value {
      top: -20px;
      left: -5px;
    }
  }

  #dot-26 {
    top: 274px;
    left: 745px;

    .years-period {
      top: 1px;
      left: -38px;
    }

    .energy-value {
      top: -20px;
      left: -5px;
    }
  }

  #dot-27 {
    top: 296px;
    left: 754px;

    .years-period {
      top: 2px;
      left: -38px;
    }

    .energy-value {
      top: -20px;
      left: -5px;
    }
  }

  #dot-28 {
    top: 323px;
    left: 765px;

    .years-period {
      top: 0px;
      left: -38px;
    }

    .energy-value {
      top: -20px;
      left: -5px;
    }
  }

  #dot-29 {
    top: 423px;
    left: 765px;

    .years-period {
      top: -4px;
      left: -38px;
    }

    .energy-value {
      top: -12px;
      left: 0px;
    }
  }

  #dot-30 {
    top: 446px;
    left: 755px;

    .years-period {
      top: -4px;
      left: -38px;
    }

    .energy-value {
      top: -12px;
      left: 0px;
    }
  }

  #dot-31 {
    top: 470px;
    left: 746px;

    .years-period {
      top: -4px;
      left: -39px;
    }

    .energy-value {
      top: -12px;
      left: 0px;
    }
  }

  #dot-32 {
    top: 492px;
    left: 735px;
    height: 8px;
    width: 8px;

    .years-period {
      top: -4px;
      left: -58px;
      width: 50px;
    }

    .energy-value {
      top: -12px;
      left: 0px;
    }
  }

  #dot-33 {
    top: 519px;
    left: 726px;

    .years-period {
      top: -5px;
      left: -38px;
    }

    .energy-value {
      top: -12px;
      left: 0px;
    }
  }

  #dot-34 {
    top: 543px;
    left: 716px;

    .years-period {
      top: -6px;
      left: -38px;
    }

    .energy-value {
      top: -12px;
      left: 0px;
    }
  }

  #dot-35 {
    top: 568px;
    left: 706px;

    .years-period {
      top: -13px;
      left: -33px;
    }

    .energy-value {
      top: -12px;
      left: 0px;
    }
  }

  #dot-36 {
    top: 633px;
    left: 650px;

    .years-period {
      top: -7px;
      left: -38px;
    }

    .energy-value {
      top: 2px;
      left: -7px;
    }
  }

  #dot-37 {
    top: 643px;
    left: 625px;

    .years-period {
      top: -7px;
      left: -38px;
    }

    .energy-value {
      top: 2px;
      left: -7px;
    }
  }

  #dot-38 {
    top: 655px;
    left: 597px;

    .years-period {
      top: -8px;
      left: -38px;
    }

    .energy-value {
      top: 2px;
      left: -7px;
    }
  }

  #dot-39 {
    top: 668px;
    left: 563px;
    width: 8px;
    height: 8px;

    .years-period {
      top: -16px;
      left: -44px;
      width: 50px;
    }

    .energy-value {
      top: 3px;
      left: -2px;
    }
  }

  #dot-40 {
    top: 681px;
    left: 536px;

    .years-period {
      top: -14px;
      left: -25px;
    }

    .energy-value {
      top: 2px;
      left: -7px;
    }
  }

  #dot-41 {
    top: 692px;
    left: 510px;

    .years-period {
      top: -16px;
      left: -21px;
    }

    .energy-value {
      top: 1px;
      left: -6px;
    }
  }

  #dot-42 {
    top: 704px;
    left: 481px;

    .years-period {
      top: -17px;
      left: -8px;
    }

    .energy-value {
      top: 1px;
      left: -6px;
    }
  }

  #dot-43 {
    top: 702px;
    left: 391px;

    .years-period {
      top: -15px;
      left: -16px;
    }

    .energy-value {
      top: 3px;
      left: -25px;
    }
  }

  #dot-44 {
    top: 691px;
    left: 366px;

    .years-period {
      top: -14px;
      left: -11px;
    }

    .energy-value {
      top: 4px;
      left: -25px;
    }
  }

  #dot-45 {
    top: 682px;
    left: 343px;

    .years-period {
      top: -15px;
      left: -6px;
    }

    .energy-value {
      top: 4px;
      left: -25px;
    }
  }

  #dot-46 {
    top: 670px;
    left: 318px;
    width: 8px;
    height: 8px;

    .years-period {
      top: -17px;
      left: 1px;
      width: 50px;

    }

    .energy-value {
      top: 4px;
      left: -25px;
    }
  }

  #dot-47 {
    top: 661px;
    left: 295px;

    .years-period {
      top: -15px;
      left: -5px;
    }

    .energy-value {
      top: 4px;
      left: -27px;
    }
  }

  #dot-48 {
    top: 651px;
    left: 272px;

    .years-period {
      top: -15px;
      left: -7px;
    }

    .energy-value {
      top: 4px;
      left: -28px;
    }
  }

  #dot-49 {
    top: 639px;
    left: 244px;

    .years-period {
      top: -13px;
      left: -5px;
    }

    .energy-value {
      top: 4px;
      left: -28px;
    }
  }

  #dot-50 {
    top: 562px;
    left: 169px;

    .years-period {
      top: -8px;
      left: 5px;
    }

    .energy-value {
      top: -1px;
      left: -29px;
    }
  }

  #dot-51 {
    top: 538px;
    left: 159px;

    .years-period {
      top: -6px;
      left: 5px;
    }

    .energy-value {
      top: -1px;
      left: -29px;
    }
  }

  #dot-52 {
    top: 515px;
    left: 150px;

    .years-period {
      top: -6px;
      left: 5px;
    }

    .energy-value {
      top: -1px;
      left: -29px;
    }
  }

  #dot-53 {
    top: 489px;
    left: 139px;
    width: 8px;
    height: 8px;

    .years-period {
      top: -5px;
      left: 9px;
      width: 50px;
    }

    .energy-value {
      top: -1px;
      left: -29px;
    }
  }

  #dot-54 {
    top: 465px;
    left: 130px;

    .years-period {
      top: -4px;
      left: 5px;
    }

    .energy-value {
      top: -1px;
      left: -29px;
    }

  }

  #dot-55 {
    top: 441px;
    left: 120px;

    .years-period {
      top: -4px;
      left: 5px;
    }

    .energy-value {
      top: -1px;
      left: -29px;
    }
  }

  #dot-56 {
    top: 416px;
    left: 110px;

    .years-period {
      top: -2px;
      left: 6px;
    }

    .energy-value {
      top: -1px;
      left: -29px;
    }
  }

}







